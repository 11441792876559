@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-loader {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .custom-loader.hide {
    display: none; }

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  font-family: 'Inter', sans-serif;
  text-align: left;
  margin: 0px;
  padding: 0px;
  /* ***************** Authentication Css Start ***************** */
  /* ***************** Authentication Css End ***************** */
  /* ***************** Breadcrumb Css Start ***************** */
  /* ***************** Breadcrumb Css End ***************** */
  /**************Login-signup-Start********************/
  /*************Login-signup-Etart*********************/
  /**********************************/
  /**********************************/ }
  body .ant-form-item {
    margin-bottom: 20px; }
    body .ant-form-item.Disable_Form_Item .ant-form-item-label label {
      opacity: 0.6; }
    body .ant-form-item.ant-form-item-with-help {
      margin-bottom: 20px; }
      body .ant-form-item.ant-form-item-with-help .ant-form-item-explain {
        font-size: 11px;
        line-height: 16px; }
    body .ant-form-item .ant-form-item-label {
      padding-bottom: 6px;
      display: block;
      width: 100%;
      text-align: left;
      font-weight: 600;
      font-size: 13px;
      color: #2e3134;
      line-height: 16px; }
      body .ant-form-item .ant-form-item-label label {
        font-weight: 500;
        height: auto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 17px;
        min-height: 17px;
        color: #2e3134; }
        body .ant-form-item .ant-form-item-label label::after {
          display: none; }
    body .ant-form-item .ant-form-item-control .ant-input-group .ant-input-group-addon .ant-select {
      text-align: left;
      font-weight: 500; }
      body .ant-form-item .ant-form-item-control .ant-input-group .ant-input-group-addon .ant-select .ant-select-selector {
        color: #000; }
    body .ant-form-item .ant-select .ant-select-selector {
      font-size: 13px;
      text-align: left; }
  body .Auth_container {
    min-height: 100vh;
    background: url(/static/media/login-bg.5a17d144.png) no-repeat;
    background-position: 50% 5%;
    background-size: cover;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative; }
  body .Auth_Form_Content {
    padding: 15px 150px 15px 0;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center; }
    @media only screen and (max-width: 767px) {
      body .Auth_Form_Content {
        padding: 15px;
        -webkit-justify-content: center;
                justify-content: center; } }
  body .Auth_Form_Block {
    padding: 70px 40px 37px;
    background: #ffffff;
    width: 100%;
    max-width: 498px;
    border-radius: 12px; }
    @media only screen and (max-width: 767px) {
      body .Auth_Form_Block {
        padding: 25px 20px; } }
  body .Auth-Heading {
    padding: 33px 0; }
    body .Auth-Heading h6 {
      margin: 0;
      font-size: 24px;
      line-height: 29px;
      color: #2e3134;
      font-weight: 700; }
    body .Auth-Heading p {
      margin: 0;
      font-size: 14px;
      line-height: 21px;
      color: #697278;
      font-weight: 400; }
    @media only screen and (max-width: 767px) {
      body .Auth-Heading {
        padding: 20px 0; } }
  body .Auth_Logo {
    margin: 0 auto;
    width: 140px;
    height: 61px; }
    @media only screen and (max-width: 1024px) {
      body .Auth_Logo {
        width: 100%;
        height: auto; } }
    body .Auth_Logo img {
      width: 100%;
      object-fit: cover; }
  body .Login_Footer {
    -webkit-flex: 0 0 61px;
            flex: 0 0 61px; }
  body .Main_Container {
    background: #f3f5f7;
    position: relative;
    min-height: calc(100vh - 148px); }
    body .Main_Container .ant-page-header-heading-title {
      font-size: 24px;
      padding-top: 20px; }
  body .ant-breadcrumb {
    text-align: left;
    margin-bottom: 20px; }
    body .ant-breadcrumb + .ant-page-header-heading {
      margin-top: 0; }
      @media only screen and (max-width: 767px) {
        body .ant-breadcrumb + .ant-page-header-heading {
          margin-top: 6px; } }
      body .ant-breadcrumb + .ant-page-header-heading .ant-page-header-heading-tags .ant-dropdown-link {
        color: #697278;
        font-size: 16px;
        line-height: 32px;
        font-weight: 600; }
      body .ant-breadcrumb + .ant-page-header-heading .ant-page-header-heading-left,
      body .ant-breadcrumb + .ant-page-header-heading .ant-page-header-heading-extra {
        margin: 0; }
    body .ant-breadcrumb span:first-child .ant-breadcrumb-link {
      font-weight: 700; }
    body .ant-breadcrumb .ant-link {
      font-weight: 700; }
  body .Signup_link {
    margin: 20px 0 0 0;
    font-size: 14px;
    line-height: 17px;
    color: #697278;
    font-weight: 400;
    text-align: center; }
    body .Signup_link a {
      font-weight: 600;
      color: #3d8ff8; }
  body h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: #454b50;
    margin: 5px 0; }
  body .border_E9 {
    border: 1px solid #e4e9ef;
    transition: 100ms all ease-in-out; }
    body .border_E9:hover {
      border: 1px solid #3d8ff8; }
  body .borderBottom {
    border-bottom: 1px solid #e4e9ef; }
  @media only screen and (max-width: 1024px) {
    body .ant-page-header {
      padding: 16px 20px; } }

.ant-select-item-option:hover {
  background-color: #3d8ff8 !important;
  color: #ffffff; }

