.custom-loader {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    &.hide {
      display: none;
    }
  }
  