@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
// ********** Color variables **********
$whiteColor: #ffffff;
$primaryColor: #3d8ff8;
$black-2e: #2e3134;
$gray-95: #959ca1;
$gray-69: #697278;
$gray-f4: #f4f5f7;
$gray-91: #919da8;
$gray-d9: #d9d9d9;
$gray-e4: #e4e9ef;
$black-45: #454b50;
$green-04: #048a8a;
$red-63: #ff7c63;
$orange-e8:#e8950d;
$red-59: #ff5959;
$gray-f3: #f3f5f7;
$blue-gradient: linear-gradient(to right, #3d8ff8, #6acefa);
$white-f9: #f9fafb;
$primary-light: #f1f8ff;
$black-22: #222c3c;
$light-blue: #e9f4ff;
$gray-6b: #6b7780;
$green-ee: #eeffe6;
$green-f9: #f9fff7;
$blue-c6: #c6daef;
$green-6c: #6cb815;
// ********** font-size  variables **********
// $xl: 1440px;
// $lg: 1024px;
// $md: 991px;
// $sm: 767px;
// $xs: 575px;
// ********** media query  variables **********
$Desktop: 'only screen and (max-width : 1440px)';
$Laptop: 'only screen and (max-width : 1199px)';
$Ipad: 'only screen and (max-width : 1024px)';
$IpadS: 'only screen and (max-width: 991px)';
$PhoneL: 'only screen and (max-width: 767px)';
$PhoneS: 'only screen and (max-width: 575px)';

