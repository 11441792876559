@import './variables.scss';
body {
    font-family: 'Inter', sans-serif;
    text-align: left;
    margin: 0px;
    padding: 0px;
    .ant-form-item {
      margin-bottom: 20px;
      &.Disable_Form_Item {
        .ant-form-item-label {
          label {
            opacity: 0.6;
          }
        }
      }
      &.ant-form-item-with-help {
        margin-bottom: 20px;
        .ant-form-item-explain {
          font-size: 11px;
          line-height: 16px;
        }
      }
      .ant-form-item-label {
        padding-bottom: 6px;
        display: block;
        width: 100%;
        text-align: left;
        font-weight: 600;
        font-size: 13px;
        color: $black-2e;
        line-height: 16px;
        label {
          font-weight: 500;
          height: auto;
          font-size: 14px;
          letter-spacing: 0.2px;
          line-height: 17px;
          min-height: 17px;
          color: $black-2e;
          &::after {
            display: none;
          }
        }
      }
      .ant-form-item-control {
        .ant-input-group {
          .ant-input-group-addon {
            .ant-select {
              text-align: left;
              font-weight: 500;
              .ant-select-selector {
                color: #000;
              }
            }
          }
        }
      }
      .ant-select {
        .ant-select-selector {
          font-size: 13px;
          text-align: left;
                }
            }  
        }
  /* ***************** Authentication Css Start ***************** */
  .Auth_container {
    min-height: 100vh;
    background: url('../src/Assets/Images/login-bg.png') no-repeat;
    background-position: 50% 5%;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
  }
  .Auth_Form_Content {
    padding: 15px 150px 15px 0;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media #{$PhoneL} {
      padding: 15px;
      justify-content: center;
    }
  }
  .Auth_Form_Block {
    padding: 70px 40px 37px;
    background: $whiteColor;
    width: 100%;
    max-width: 498px;
    border-radius: 12px;
    @media #{$PhoneL} {
      padding: 25px 20px;
    }
  }
  .Auth-Heading {
    padding: 33px 0;
    h6 {
      margin: 0;
      font-size: 24px;
      line-height: 29px;
      color: $black-2e;
      font-weight: 700;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 21px;
      color: $gray-69;
      font-weight: 400;
    }
    @media #{$PhoneL} {
      padding: 20px 0;
    }
  }
  .Auth_Logo {
    margin: 0 auto;
    width: 140px;
    height: 61px;
     @media #{$Ipad} {
      width: 100%;
      height: auto;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .Login_Footer {
    flex: 0 0 61px;
  }
  .Main_Container {
    background: $gray-f3;
    position: relative;
    min-height: calc(100vh - 148px);
    .ant-page-header-heading-title{
      font-size: 24px;
      padding-top: 20px;
    }
  }
  /* ***************** Authentication Css End ***************** */
  /* ***************** Breadcrumb Css Start ***************** */
  .ant-breadcrumb {
    text-align: left;
    margin-bottom: 20px;
    + .ant-page-header-heading {
      margin-top: 0;
      @media #{$PhoneL} {
        margin-top: 6px;
      }
      .ant-page-header-heading-tags {
        .ant-dropdown-link {
          color: $gray-69;
          font-size: 16px;
          line-height: 32px;
          font-weight: 600;
        }
      }
      .ant-page-header-heading-left,
      .ant-page-header-heading-extra {
        margin: 0;
      }
    }
    span:first-child {
      .ant-breadcrumb-link {
        font-weight: 700;
      }
    }
    .ant-link {
      font-weight: 700;
    }
  }
 /* ***************** Breadcrumb Css End ***************** */
 /**************Login-signup-Start********************/
      .Signup_link {
        margin: 20px 0 0 0;
        font-size: 14px;
        line-height: 17px;
        color: $gray-69;
        font-weight: 400;
        text-align: center;
        a {
          font-weight: 600;
          color: $primaryColor;
        }
      }
/*************Login-signup-Etart*********************/
      h2{
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        color: $black-45;
        margin:5px 0;
      }
      .border_E9{
        border: 1px solid $gray-e4;
        transition: 100ms all ease-in-out;
        &:hover{
          border: 1px solid $primaryColor;
        }
      }
      .borderBottom{
        border-bottom: 1px solid $gray-e4;
      }
      .ant-page-header{
        @media #{$Ipad} {
          padding: 16px 20px;
        }
      }
/**********************************/
/**********************************/
    }
    .ant-select-item-option{
        &:hover{
        background-color: $primaryColor !important;
        color: $whiteColor;
        }
    }
